@use '@mondough/monzo-ui/src/tokens/globals' as *;

.wrapper {
  gap: 16px;
}

.title {
  margin: 0;
  max-width: 14ch;
}

p.helper-text {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}

p.notes {
  background-color: $color-soft-white;
  padding: 12px 16px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  margin: 16px 0;
}
